import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from 'react-router';

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [loginStatus, setLoginStatus] = useState("");
  const history = useHistory();
  axios.defaults.withCredentials = true;

  const login = (username, password) => {
    axios.post('/login', {
      username: username,
      password: password,
    }).then((response) => {
      if (response.data.message) {
        setLoginStatus(response.data.message);
      } else {
        history.push("/stock")
      }
    });
  };

  useEffect(() => {
    axios.get("/login").then((response) => {
      if (response.data.loggedIn === true) {
        history.push("/stock")
      }
    });
  });

  return (
    <div>
      {/*}
      <div className="registration">
        <h1>Registration</h1>
        <label>Username</label>
        <input
          type="text"
          onChange={(e) => {
            setUsernameReg(e.target.value);
          }}
        />
        <label>Password</label>
        <input
          type="text"
          onChange={(e) => {
            setPasswordReg(e.target.value);
          }}
        />
        <button onClick={() => register(usernameReg, passwordReg)}> Register </button>
      </div>
        */}

      <div className="login">
        <h1>Login</h1>
        <input
          type="text"
          placeholder="Username..."
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
        <input
          type="password"
          placeholder="Password..."
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <button onClick={() => login(username,password)}> Login </button>
      </div>

      <p style={{textAlign : 'center'}}>{loginStatus}</p>
    </div>
  );
}