import React, {Component} from 'react';
import FaqBoxContainer from './faqBoxContainer';

class Faq extends Component{
  render(){
    return(
      <div className= "faqBox">
        <FaqBoxContainer header = "What is a Salt Water Chlorine Generator?" answer ={'The salt water chlorine generator uses electrolysis in the presence of dissolved salt (NaCl) to produce hypochlorous acid (HClO) and sodium hypochlorite (NaClO), which are the sanitizing agents already commonly used in swimming pools. \n Chlorine generators are devices that assist in the automation of pool and spa water management. Chlorine generators use a small amount of salt (Sodium Chloride) dissolved in the water to make chlorine. Through the process of electrolysis, the generators separate the Chlorine from the Sodium and produce pure chlorine gas which is absorbed into the water as a sanitizing agent. The unused chlorine combines with the free sodium converting back into natural salt minimizing the need to add more salt. Chlorine generators have been around since the mid 1970’s primarily in Australia and their popularity is quickly spreading across the United States, Canada and Europe.'}/>
        <FaqBoxContainer header = "Is it more cost effective than conventional Chlorine?" answer ={"Generally, most spa owners use their spas continuously through the year and will spend around £200 on chemicals. A 20kg bag of PDV salt will cost around £20 and last a few years. The unit is powered at 7 watts so the electrical cost is minimal. All with the added benefits of: \n•	Softer water from salt system\n•	Sanitation consistency \n•	The time saved on weekly maintenance\n•	No shock dosing or draining because of fluctuating levels\n•	A spa that is always ready to relax in when you are"}/>
        <FaqBoxContainer header = "Should I do anything before switching to a SmarterSpa or ChlorMaker?" answer ={"We recommend that you drain and refill your spa with fresh water. Tap water generally has the correct PH and alkalinity levels. We recommend some preparation depending on the type of sanitizer you were using:\n●    Any type of chlorine sanitizer simply rinse out the spa well.\n●    Any type of bromine sanitizer rinse out the spa very well and CLEAN the filter.\n●    Any type of hydrogen peroxide system clean the spa extremely well and REPLACE the filter.\nThen install your new saltwater system, add salt and enjoy the soft water and low maintenance."}/>
        <FaqBoxContainer header = "What is the salt concentration?" answer ={"Most of today’s modern saltwater pools have a level of 3,000 PPM parts per million and higher. ControlOMatic’s spa chlorinators use less salt more efficiently at 1500-2,000 PPM. We recommend 1.5 pounds of salt per 100 gallons of water, that is about a teaspoon per gallon. Put in more the chlorinator may shut down from high salinity.\n\n•	Ocean Water – 35,000 parts per million\n•	Human Tears – 9,000 parts per million\n•	Ability To Taste Salt – 5,000 parts per million\n•	Modern Salt Pools – 3,000 parts per million\n•	ChlorMaker and SmarterSpa – 2,000 parts per million Max\n•	MegaChlor – 2,000 minimum, no max\n\nIt is better to put in less salt and add more after the chlorinator is operating. If you put in too much, you will have to drain out some water and add fresh water to lower the salt concentration."}/>
        <FaqBoxContainer header = "Will it work with my spa size?" answer ={"Producing 30g/day of Chlorine, our products have the highest output and longest lasting electrode on the market. If it's less than 1000 gal, which almost all spas are, then our Chlorine generators will have your spa purified and under control."}/>
        <FaqBoxContainer header = "Someone told me OZONE and/or UV treatment is all I need, is that true?" answer ={"Both Ozone and UV systems are designed to burn organic material – NOT BACTERIA. That is why both systems still require the use of some kind of sanitizing agent like chlorine or bromine. Both Ozone and UV help to lower the spa’s demand for chlorine and will allow any saltwater system to operate on a lower power level and last longer."}/>
        <FaqBoxContainer header = "Aren't Salt Water Chlorinator's corrosive?" answer ={"We wouldn't be in business if our products corroded spas! Corrosion of pool and spa components is very common with or without salt in your water. Not to mention, each gallon of liquid chlorine contains ONE POUND OF SALT!  Corrosion has to do with your waters hardness, which is recommended at:\n\n•	Calcium Hardness 200-250 PPM Higher than that, calcium will build up on your jet spouts and water line, and lower, components can corrode.\n\nHardness Increaser and Decreaser can be found at any local Pool or Spa supply."}/>
        <FaqBoxContainer header = "Are your products under Warranty?" answer ={"All of our products come with a 1-Year Limited product Warranty. We also provide a 30-day satisfaction guarantee. We are so confident that you will see the results you're looking for, if you're not happy, simple return the undamaged product in its original packaging within 30 days for a full refund. Our electrodes have a 7,000 hour life span, higher than any other Salt Water Chlorinator on the Market.\n\nTry it risk free today!"}/>
        <FaqBoxContainer header = "What are your company's Green initiatives?" answer ={"We buy from a small manufacturer that takes great pride in using resources and materials efficiently: recycling boxes, limiting electric consumption, and eliminating waste."}/>
      </div>
    );
  }
}

export default Faq;