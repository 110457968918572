import React, {Component} from 'react';
import AboutStep from './AboutStep';
import AboutBenefits from './AboutBenefits';

class About extends Component{
  render(){
    return(
      <div>
        <div className="banner">
          <div className="BannerTextArea">
              <h1>Always ready. Always clear. Always chlorinating.</h1>
              <h2>With Auto-chlorination technology maintaining pristine water is a breeze.</h2>
          </div>
        </div>

        <div className="aboutSummary">
        <AboutStep imageFile ='stepImage1' imageAlt ='chlorinator salt' header ='Simply pour In salt' description ="Salt, the earth's most abundant resource, naturally contains chlorine. Pouring salt in your spa water prepares it for the chlorination process. It's easy, just add salt!"/>
        <AboutStep imageFile ='stepImage2' imageAlt ='chlorinator interface' header ='Add chlorine generator' description ="Our units extract chlorine contained in salt molecules. Immerse the tube in your hot tub, you can observe the satisfying process of bubbles forming while chlorine is extracted."/>
        <AboutStep imageFile ='stepImage3' imageAlt ='relaxing hottub' header ='Clean hassle-free H20' description ="Enjoy the ease of salt water chlorination. Odorless and less harsh salt water chlorination efficiently maintains chlorine levels while being kinder to your skin and senses."/>
       </div>

       <div className="aboutExplanationSection">
        <div className="aboutExplanation"><h2>The Science.</h2>
            <p>
              <br/><br/>Electrolyzed water (EOW), or HOCl (hypochlorous acid), is an all-natural, no-rinse, non-toxic, non-irritating, and environment-safe antimicrobial solution, produced through an electrochemical reaction of water, salt, and electricity. It serves as a particularly potent antimicrobial agent, being approximately 100 times as effective as chlorine bleach.
              <br/><br/>Electrolyzed water (EOW) is generated by the electrolysis of a dilute NaCl (table salt) solution interacting with an electrolytic cell. This process creates an anolyte solution predominantly of hypochlorous acid (HOCl). EOW is highly biocidal and can kill common foodborne pathogens such as Salmonella, E. coli, Listeria, and Campylobacter in less than 30 seconds on contact. It has been proven to be effective against bacterial spores and viruses that are highly resistant to other disinfectants. EOW is also capable of dislodging and removing biofilm, which can be a major contributor to contamination in pipes and clean-in-place (CIP) systems, i.e. in the systems behind beverage and dairy manufacturing.
              <br/><br/>Unlike other oxidants, such as ozone (O₃) and chlorine dioxide (ClO₂), which are gases and rapidly leave the solution in seconds to minutes, HOCl is a liquid that is stable in solution, and it maintains biocidal concentrations for up to several months. This stability in solution allows for HOCl to be used in many applications in the food industry, from the farmer to the table—not only for direct food contact but also for the disinfection of food contact surfaces. When stored in ideal conditions in closed containers protected from oxygen and light, HOCl has been proven to be stable in solution for several months. Even if it is stored in buckets or other open containers, it will still remain stable for several hours.
              <br/><br/>When HOCl is applied on food or contact surfaces, it behaves as an oxidizing agent. When reduced by organic matter, it leaves no harmful residues and therefore no potable water post-rinse is required. The aforementioned features make electrolyzed water (hypochlorous acid) a superior operational solution for any food service provider, saving time and resources and enabling frequent, real-time application.
              <br/><br/>There are a broad range of applications for the use of this technology in industries such as agriculture, food and beverage, hospitality, healthcare, and water treatment. EOW can be safely and efficiently utilized through a variety of methods such as direct application, pressure spraying, dosing, and fogging.
            </p>
          </div>
        </div>

        <div className="electrolysisVideoContainer">
          <iframe title="how to use" src="https://player.vimeo.com/video/442183623" width="80%" height="100%" frameBorder="0" allow="autoplay; fullscreen"></iframe>
          <p className ="videoSubheader"><a href="https://vimeo.com/442183623">NatureChlor Operation</a> from <a href="https://vimeo.com/user120363657">Kyle Garcia</a> on <a href="https://vimeo.com">Vimeo</a>.</p>
        </div>
 
        <div className="aboutBenefitsSection">
          <h2 className="benefitsHeader">The benefits.</h2>

          <AboutBenefits boxStyle = 'aboutBenefitBox1' imageStyle = 'aboutBenefitBoxImage1' imageFile ='aboutBenefitImage1' imageAlt ='clean hottub' header='Minimal Maintenance.' description ="One of the best benefits of a salt water chlorinator is the minimal maintenance it requires. These types of hot tubs usually clean themselves, meaning the owner will have an easier time. There is no need to add other sanitizers."/>
          <AboutBenefits boxStyle = 'aboutBenefitBox2' imageStyle = 'aboutBenefitBoxImage2' imageFile ='aboutBenefitImage2' imageAlt ='efficient hottub' header='Cost Effective.' description ="Salt water chlorinators are very cheap in the long run. This is because the cost of the salt water chlorinator pays for itself as the price of salt is lower than that of regular chlorine."/>
          <AboutBenefits boxStyle = 'aboutBenefitBox1' imageStyle = 'aboutBenefitBoxImage1' imageFile ='aboutBenefitImage3' imageAlt ='healthy hottub owner' header='Health Benefits.' description ="Salt water is healthier for people as it is softer on the skin, eyes, and hair when compared to chlorine treated water. High levels of chlorine and additives are responsible for the irritation that some people experience when swimming. This makes salt water hot tubs a good option for people with allergies."/>
          <AboutBenefits boxStyle = 'aboutBenefitBox2' imageStyle = 'aboutBenefitBoxImage2' imageFile ='aboutBenefitImage4' imageAlt ='multi-use chlorinator' header='Versatility.' description ="Salt chlorinators can be installed in any type of water body whether it is a small spa or hot tub or a domestic swimming pool. The process of installing this system is usually quite straight forward and can be installed by a competent DIY enthusiast."/>
        </div>
      </div>
    );
  }
}

export default About;