import React, {Component} from 'react';

class AboutStep extends Component{
  
  render(){
    return(
       <div className="stepBox">
            <div className="stepImageBox">
                <img className="stepImage" src ={`../../images/${this.props.imageFile}.png`} alt={this.props.imageAlt}></img>
            </div>
            <h2 className="stepHeader">{this.props.header}</h2>
            <p className="stepDescription">{this.props.description}</p>
        </div>
    );
  }
}

export default AboutStep;