import React, {Component} from 'react';

class Contact extends Component{
  render(){
    return(
      <div>
        <div className="contactInfoContainer">
          <h2 className="contactHeader blackFont">Contact Us</h2>
          <p className="contactSubtext">For any queries or concerns please contact us at <br/>richard21austin@gmail.com</p>
       </div>
     </div>
    );
  }
}

export default Contact;