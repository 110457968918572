import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Product from './Product.js';
import ProductsList from './Products.json';

export default function Products(){

  const [products, setProducts] = useState([]);

  return(
    <div>
      <div className="navigationOpacity">
        <div className="grid-container">
          <div className="grid-row">

            {ProductsList.length ?
              ProductsList.map((product, i) =>
                <Product 
                  key = {i}
                  name = {product.name}
                  price = {(product.price)}
                  stock = {product.stock}
                />
              )
              : null
            } 
          </div>
        </div>
      </div>
    </div>
);
}