import React, { Component } from 'react';

class StockItem extends Component{

  constructor() {
    super();
    this.state = {
        newPrice: '',
        newStockQuantity: '',
    };
  }

  addZeroes(num) {
    const dec = num.toString().split('.')[1]
    const len = dec && dec.length > 2 ? dec.length : 2
    return Number(num).toFixed(len)
  }
  
  priceChangeHandler = (event) => {
    this.setState({newPrice: event.target.value});
  }

  stockChangeHandler = (event) => {
    this.setState({newStockQuantity: event.target.value});
  }

  render(){
    return(
      <div className="stockItemContainer">
          <p className="stockRecord">{this.props.name}</p>
          <p className="stockRecord">£{this.addZeroes(this.props.price)}</p>
          <p className="stockRecord">{this.props.stock}</p>
          <form style={{border: 'none'}} className="stockRecord stockForm">
            <input
                type='text'
                onChange={this.priceChangeHandler}
                value={this.state.newPrice}
            />
            <input
                type='submit'
                value='Submit'
                onClick={() => {this.props.updateProductPrice(this.props.name, this.state.newPrice)}}
            />
          </form>
          <form style={{border: 'none'}} className="stockRecord stockForm">
            <input
                type='text'
                onChange={this.stockChangeHandler}
                value={this.state.newStock}
            />
            <input
                type='submit'
                value='Submit'
                onClick={() => {this.props.updateProductQuantity(this.props.name, this.state.newStockQuantity)}}
            />
          </form>
      </div>
);
}
}

export default StockItem;