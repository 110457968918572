import React, { useEffect, useState} from 'react';
import { PayPalButton } from "react-paypal-button-v2";
import ProductInformationBox from './ProductInformationBox';
import chlormakerManual from '../manuals/chlormaker-manual.pdf';
import megachlorManual from '../manuals/megachlor-manual.pdf';
import smarterspaManual from '../manuals/smarterspa-manual.pdf';

export default function ProductMoreInformation(props){

  const [pdf, setPdf] = useState('');
  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState(0);

  const removeWhiteSpace = (string) => {
    return string.replace(/\s/g, '')
  }

  const addZeroes = (num) => {
    const dec = num.toString().split('.')[1]
    const len = dec && dec.length > 2 ? dec.length : 2
    return Number(num).toFixed(len)
  }

  useEffect(() => {
    if(props.productInfo !== '') {

      setProductName(removeWhiteSpace(props?.productName));
      setProductPrice(addZeroes(props?.productInfo.price));

        if (props?.productInfo?.product_type === 'megachlor') { 
          setPdf(megachlorManual)
        }

        if (props?.productInfo?.product_type === 'smarterspa') { 
          setPdf(smarterspaManual)
        }

        if (props?.productInfo?.product_type === 'chlormaker') { 
          setPdf(chlormakerManual)
        }
      }
  }, [props]);

  return(
    <>
      <div className="productMain">
        <div className="productImageBox">
            <p className="productSummaryMainText">{props.summary}</p>
            <div className="productInformationBoxWrapper">
              {props.features !== '' ?
              <ProductInformationBox header="Features" moreInformation={props.features}></ProductInformationBox>
              : null}
              {props.moreInformation !== '' ?
              <ProductInformationBox header="More Information" moreInformation={props.moreInformation}></ProductInformationBox>
              : null}
              {props.instructions !== '' ?
              <ProductInformationBox header="How to use" moreInformation={props.instructions}></ProductInformationBox>
              : null}
              {props.featureOne !== '' ?
              <div className="productMainFeaturesBox">
                <div className="productFeature">
                  <img className="FeatureImageOne" src="images/FeatureImage1.png" alt="chlorinator suitability"></img>
                  <p className="FeatureText">{props.featureOne}</p>
                </div>
                <div className="productFeature">
                  <img className="FeatureImageTwo" src="images/FeatureImage2.png" alt="chlorinator boost mode"></img>
                  <p className="FeatureText">{props.featureTwo}</p>
                </div>
                <div className="productFeature">
                  <img className="FeatureImageThree" src="images/FeatureImage3.png" alt="easy to use chlorinator"></img>
                  <p className="FeatureText">{props.featureThree}</p>
                </div>
              </div>
              : null}
            </div>
          </div>
    
        <div className="productSummaryBox">
            <h2 className="productSummaryHeader">{props.productName}</h2>
            <img className="productSelectedImage" src={`images/${productName}.jpg`} alt="SmarterSpa chlorinator"></img>
            <p className="productPrice">£{productPrice}</p>
            <p>Uk shipping only</p>
            <a href={pdf} target="_blank" style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
              <div className='manuals'>
                <p>Manual</p>
              </div>
            </a>
            <div className="productBuyNowBox">
            {props?.productInfo?.supply >= 1 ?
              <PayPalButton
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [{
                  description: productName,
                    amount: {
                      currency: "GBP",
                      value: productPrice
                    },
                  }],
                });
              }}
              onApprove={(data, actions) => {
                // Capture the funds from the transaction
                return actions.order.capture().then(function(details) {
                  // Show a success message to your buyer
                  alert("Transaction completed by " + details.payer.name.given_name);
                });
              }}
              options={{
              clientId: "AXxKjp4q2p025pG49WsQf40R2Ha8M2qbK8J6rG8kOi8ifowSwVRob_UXyMZEgeC4nt5Udnz9XCnzKIXO",
              currency: "GBP"
            }}
            />        
            : 
            <p style ={{color : 'white', background : '#434343'}}>Out of stock</p>
            }
            </div>
          </div>
        </div>

        <div className="footer">
            <p>©2021 Crystal Chlorinators  |  All Rights Reserved</p>
        </div>
        : null
        </>
  );
}