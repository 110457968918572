import React, {Component} from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";

class Home extends Component{

  constructor(){
    super();

    this.state = {
     bannerImages : ["url(../../images/BannerImage1.png)", "url(../../images/BannerImage2.png)", "url(../../images/BannerImage3.png)"],
     bannerHeaders : ["Chlorine production at your fingertips", "Peace of mind while at home or away", "Highest chlorine output on the market"],
     bannerSubheaders : ["Our chlorine producing system uses the latest technology with user friendly controls", "Whether at home or away, you can rest easy knowing your spa is generating chlorine. Your water is waiting for you to relax.", "ControlOMatic's salt water chlorinators generate more chlorine with less salt than any other salt water chlorine generator out there.",],
     currentBanner : 0,
     leftButton: "../../images/bannerLeftButton.png",
     rightButton: "../../images/bannerRightButton.png",
     mainProduct: "SmarterSpa",
     subProductImage1: "MegaChlor",
     subProductImage2: "ChlorMaker",
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.changeBannerImage(true), 5000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  changeBannerImage(ascending){
    let currentBanner = this.state.currentBanner;
    let newBanner;

    if (ascending === true)
    {
      currentBanner !== 2 ? newBanner = currentBanner + 1
      : newBanner = 0
    }
    else
    {
      currentBanner !== 0 ? newBanner = currentBanner - 1
      : newBanner = 2
    }

    this.setState({currentBanner : newBanner});
  }

  clickSubProductImageOne(){
    let oldMainProduct = this.state.mainProduct
    let oldSubProduct1 = this.state.subProductImage1
    this.setState({mainProduct: oldSubProduct1, subProductImage1: oldMainProduct})
  }

  clickSubProductImageTwo(){
    let oldMainProduct = this.state.mainProduct
    let oldSubProduct2 = this.state.subProductImage2
    this.setState({mainProduct: oldSubProduct2, subProductImage2: oldMainProduct})
  }

  render(){
    return(
      <div>
         <div className="navigationOpacity">
        <div className="banner" style={{"backgroundImage": this.state.bannerImages[this.state.currentBanner]}} id="bannerImage1">
          <div className="BannerTextArea">
            <h1 className="bannerHeader">{this.state.bannerHeaders[this.state.currentBanner]}</h1>
            <h2 className="bannerSubheader">{this.state.bannerSubheaders[this.state.currentBanner]}</h2>
          </div>
          <div className="BannerNavigationBox">
            <div className="leftButtonBox">
                <img 
                 className ="leftButton" 
                 src={this.state.leftButton} 
                 alt="previous banner"
                 onClick={() => this.changeBannerImage(false)} 
                 onMouseEnter={() => {this.setState({leftButton: "../../images/bannerLeftButtonHover.png"})}}
                 onMouseOut={() => {this.setState({leftButton: "../../images/bannerLeftButton.png"})}}>
                 </img>  
            </div> 
            <div className="progressImagesBox">
              { this.state.currentBanner === 0 ? 
                <><img className ="ProgressLeft" src="../../images/progressActive.png" alt="banner one"></img>
                <img className ="ProgressCenter" src="../../images/progressInactive.png" alt="banner two"></img>
                <img className ="ProgressRight" src="../../images/progressInactive.png" alt="banner three"></img></>
                : this.state.currentBanner === 1 ?
                <><img className ="ProgressLeft" src="../../images/progressInactive.png" alt="banner one"></img>
                <img className ="ProgressCenter" src="../../images/progressActive.png" alt="banner two"></img>
                <img className ="ProgressRight" src="../../images/progressInactive.png" alt="banner three"></img></>
                : 
                <><img className ="ProgressLeft" src="../../images/progressInactive.png" alt="banner one"></img>
                <img className ="ProgressCenter" src="../../images/progressInactive.png" alt="banner two"></img>
                <img className ="ProgressRight" src="../../images/progressActive.png" alt="banner three"></img></>
              }
            </div>
            <div className="rightButtonBox">
                <img 
                className ="rightButton" 
                src={this.state.rightButton} 
                alt="next banner" 
                onClick={() => this.changeBannerImage(true)}
                onMouseEnter={() => {this.setState({rightButton: "../../images/bannerRightButtonHover.png"})}}
                onMouseOut={() => {this.setState({rightButton: "../../images/bannerRightButton.png"})}}>
                </img>
            </div>
          </div>                    
          <p className="HeaderReadMoreButton aboutLink" >
            <Link to ="/about" style={{color: "inherit"}}>READ MORE</Link>
          </p>
        </div>
      </div>

      <div className="benefitsSection">
        <div className="benefit1">
          <div className="benefitImage1">
              <img className="benImage1" src="../../images/BenefitsImage1.png" alt="cost effective chlorinator"></img>
          </div>
          <div className="benefitText1">
            <h2 className="benefitHeadText1">Highly cost effective</h2>
            <p className="benefitSubtext1">Salt water chlorinators are economical in the long run. This is because the cost of the salt water chlorinator pays for itself as the price of salt is lower than that of regular chlorine. </p>
          </div>
          </div>    

        <div className="benefit2">
          <div className="benefitImage2">
              <img className="benImage2" src="../../images/BenefitsImage2.png" alt="efficient chlorinator"></img>
          </div>
          <div className="benefitText2">
              <h2 className="benefitHeadText2">Minimal maintenance</h2>
              <p className="benefitSubtext2">One of the main benefits of a salt water chlorinator is the minimal maintenance it requires. With weekly applications of 500ml (approx) of
              white vinegar to keep the electrode scale free and easy to set parameters it makes the upkeep an easy task.</p>
          </div>
        </div>

        <div className="benefit3">
          <div className="benefitImage3">
              <img className="benImage3" src="../../images/BenefitsImage3.png" alt="safe chlorinator"></img>
          </div>
          <div className="benefitText3">
            <h2 className="benefitHeadText3">No harsh chemicals</h2>
            <p className="benefitSubtext3">Another benefit is the minimal amount of total dissolved solids (TDS) in the water. When using chlorine tablets or granules they are bulked out with other chemicals causing higher TDS. Having a low TDS improves the clarity and freshness of the water.</p>
          </div>
        </div>

        <div className="benefit4">
          <div className="benefitImage4">
              <img className="benImage4" src="../../images/BenefitsImage4.png" alt="healthy chlorinator"></img>
          </div>
          <div className="benefitText4">
            <h2 className="benefitHeadText4">Multiple health benefits</h2>
            <p className="benefitSubtext4">Salt water chlorinated spas and pools are now becoming a popular choice. With only a small amount of salt (1,500 - 3000ppm) seawater being 35,000ppm, it is softer on the skin, eyes and hair compared to using chlorine products.</p>
          </div>
        </div>
      </div>

      <div className="explanationSection">
        <div className="expBox1">
          <h2 className="expHeader">What is Electrolyzed Water?</h2>
          <p className="expSubtext" style={{color:'white'}}>Electrolyzed Water is comprised of all natural ingredients made by combining water, salt, and a little bit of electricity, that can be used as a safe & effective alternative to chlorine tablets or granules. The process is called Electrolysis which generates a powerful disinfectant (hypochlorus acid) that can be used as a safe and effective alternative to bleach and other harsh chemicals usually present in household cleaners. ControlOMatic has been utilizing the process of electrolysis for many years in the pool and spa industry to maintain safe bathing levels. We are proud to use this same technology for everyday spa and pool users.</p>
        </div>
      </div>

      <div className="productSection">
        <div className="productImages">
          <div className="mainProduct">
            <img className="mainProductImage" id ="smarterSpa" src={`images/${this.state.mainProduct}.jpg`} alt="SmarterSpa chlorinator"></img>
          </div>
          <div className="subProducts">
            <img className="subProductImage1" id="megaChlor" src={`images/${this.state.subProductImage1}.jpg`} alt="MegaChlor chlorinator" onClick={() => this.clickSubProductImageOne()}></img>
            <img className="subProductImage2" id="chlorMaker" src={`images/${this.state.subProductImage2}.jpg`} alt="ChlorMaker chlorinator" onClick={() => this.clickSubProductImageTwo()}></img>
         </div>
        </div>

        <div className="productInformation">
          <h2 className="productHeader">OUR PRODUCTS</h2>
          <h2 className="productSubHeader">{this.state.mainProduct}</h2>
          { this.state.mainProduct === "SmarterSpa" ?
            <p className="productBulletList">✔Built-in chlorine detection technology: will not over chlorinate your spa.<br />
                ✔Automatic cleaning: polarity reverses periodically to clean and help reduce calcium build-up.<br />
                ✔Easy to use control box: 3 buttons and 4 lights for operation, mounts to the spa’s skirt.<br />
                ✔Low 1,500 to 2,000 PPM salt concentration.<br />
                ✔Salt level indicator technology: lets you know when to add salt to maintain or boost chlorine production.<br />
                ✔Boost mode allows for manual control.<br />
                ✔Cell life span: 7,000 hours of chlorine production.</p>
          : this.state.mainProduct === "ChlorMaker" ?
            <p className="productBulletList">✔Adjustable power levels: built-in ability to control chlorine production.<br />✔10 time based power levels of chlorine production.<br />✔Easy to use control box: 3 buttons and 4 lights for operation, mounts to the spa’s skirt.<br />✔Automatic cleaning: polarity reverses periodically to clean and help reduce calcium build-up.<br />✔Salt level indicator technology: lets you know when to add salt to maintain or boost chlorine production.</p>
          : this.state.mainProduct === "MegaChlor" ?
            <p className="productBulletList">✔Built-in chlorine detection technology. Automatically controls the chlorine level in your pool.<br />✔For swimming pools up to 17,000 gallons.<br />✔Easy to install into existing pool pipework.<br />✔10 adjustable power levels: built-in ability to control chlorine production.<br />✔Easy to use control box: 3 buttons and 4 lights for operation, mounts to the spa’s skirt.<br />✔Automatic cleaning: polarity reverses periodically to clean and help reduce calcium build-up.<br />✔Salt level indicator technology: lets you know when to add salt to maintain or boost chlorine production.<br /><br />Note:- <i>Must only be operated with the pool pump running</i></p>
          : null
          }
          <div className="readMoreBox">
              <p className="readMoreButton productsLink"><Link to ="/products" className="navLink" style={{color: "inherit"}}>READ MORE</Link></p>
          </div>
        </div>
      </div>

      <div className="footer">
          <p>©2021 Crystal Chlorinators  |  All Rights Reserved</p>
      </div>   

  </div> 
    );
  }
}

export default Home;