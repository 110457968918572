import React, { useState } from 'react';

function ProductInformationBox(props){

  const images = {
    active: '../../images/faqHeaderArrowActive.png',
    inactive: '../../images/faqHeaderArrowInactive.png',
  };
  const [statusImage, setStatusImage] = useState(images.inactive);
  const [clickStatus, setClickStatus] = useState(false);

  const ProductInformationClick = () => {
    setStatusImage(images[clickStatus ? 'inactive' : 'active']);
    setClickStatus(!clickStatus);
  }

  return(
    <div>
      <div className="productInformationContainer" onClick ={() => ProductInformationClick()}>
        <div className="StatusArrowImageBox">
          <img className="faqHeaderArrowImage" src={statusImage} alt="FAQ expand answer"></img>
        </div>
        <p className ="moreInformationHeader">{props.header}</p>
      </div>
      {clickStatus === true ?
        <div className="faqAnswerBox">{props.moreInformation}</div> : null
      }
    </div>
);
}

export default ProductInformationBox;