import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import StockItem from './StockItem';

function Stock(){

  const [products, setProducts] = useState([]);
  const history = useHistory();

  const getProducts = () => {
    axios.get('/products')
    .then((response) => { 
      let responseData = response.data.data
      if(responseData){
        setProducts(response.data.data);
      }
    }, (error) => {
      console.log(error);
    });
  }

  const updateProductQuantity = (productName, quantity) => {
    quantity = parseInt(quantity);
    axios.put('/productQuantity', {
      params: {
        productName: productName,
        quantity: quantity
      }
    }).then((response) => {
      getProducts();
    }, (error) => {
      console.log(error);
    });
  }

  const updateProductPrice = (productName, price) => {
    price = parseFloat(price);
    axios.put('/productPrice', {
      params: {
        productName: productName,
        price: price
      }
    }).then((response) => {
      getProducts();
    }, (error) => {
      console.log(error);
    });
  }

  axios.defaults.withCredentials = true;
  useEffect(() => {
    axios.get("/login").then((response) => {
      if (response.data.loggedIn === true) {
        getProducts();
      }
      else {
        history.push("/login");
      }
    });
  }, []);

  return(
    <div className="stockContainer">
        <div className="stockHeaders">
          <p className="stockHeader">Product name</p>
          <p className="stockHeader">Price</p>
          <p className="stockHeader">Quantity in stock</p>
          <p className="stockHeader stockForm">New Price</p>
          <p className="stockHeader stockForm">New Stock</p>
        </div>
          {products.length ?
              products.map(product =>
              <StockItem
              name = {product.name}
              price = {product.price}
              stock = {product.supply}
              updateProductQuantity = {updateProductQuantity}
              updateProductPrice  = {updateProductPrice}
              />
              )
              : null
          } 
    </div>
);
}


export default Stock;