import React, {Component} from 'react';

class Error extends Component{
  render(){
    return(
      <div>
        <h1>Page not found.</h1>
      </div>
    );
  }
}

export default Error;