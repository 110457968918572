import React, {Component} from 'react';

class AboutBenefits extends Component{
  render(){
    return(
      <div className ={this.props.boxStyle}>
          <div className ={this.props.imageStyle}>
              <img className="aboutBenefitImage" src ={`../../images/${this.props.imageFile}.png`} alt={this.props.imageAlt}></img>
          </div>
          <div className="aboutBenefitText">
              <h2 className="aboutBenefitTextHeader">{this.props.header}</h2>
              <p className="aboutBenefitTextExplanation">{this.props.description}</p>
          </div>
      </div>
    );
  }
}

export default AboutBenefits;