import React, { useState } from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";

function NavBar(){

  const [OpenMobileNavBar, setOpenMobileNavBar] = useState(false);

  const clickMobileNavBar = () => {
    setOpenMobileNavBar(!OpenMobileNavBar);
  }

    return(
      <div>
        <div className="HeaderContainer">
          <header>
            <img className="navMenuIcon" src="images/mobile-nav-icon.jpg" onClick={() => clickMobileNavBar()} alt ="mobile Navigation Icon"></img>
            <img className="logo" src="images/logo.png" alt="Crystal Chlorinators logo"></img>
            <ul className ="navList">
              <div>
                <Link to ="/" className="navLink">Home</Link>
                <Link to ="/about" className="navLink">About</Link>
                <Link to ="/products" className="navLink">Products</Link>
                <Link to ="/faq" className="navLink">Faq</Link>
                <Link to ="/contact" className="navLink">Contact</Link>
              </div>
            </ul>
          </header>
          {OpenMobileNavBar === true ?
          <div className ="mobileNavTab">
            <div className="mobileNavCloseContainer">
              <img className="mobileNavClose" src="images/close.png" onClick={() => clickMobileNavBar()}></img>
            </div>
            <ul className="mobileNavList">
              <Link to ="/" className="navLink">HOME</Link>
              <Link to ="/about" className="navLink">ABOUT</Link>
              <Link to ="/products" className="navLink">PRODUCTS</Link>
              <Link to ="/faq" className="navLink">FAQ</Link>
              <Link to ="/contact" className="navLink">CONTACT</Link>
            </ul>
          </div>
          : null 
          }
          <div className ="PromotionBanner">
            <p className ="PromotionText">FREE DELIVERY ON ALL ORDERS. PRICES INCLUSIVE OF VAT.</p>
          </div>
        </div>
      </div>
    );
}

export default NavBar;