import React, {Component} from 'react';

class FaqBoxContainer extends Component{

  constructor(){
    super();

    this.answerQuestion = this.answerQuestion.bind(this);

    this.state = {
      questionClicked: false,
      questionStatusImage : "../../images/faqHeaderArrowInactive.png"
    };
  }

  answerQuestion(){

    this.state.questionClicked === false ? this.setState({ questionClicked : true, questionStatusImage : "../../images/faqHeaderArrowActive.png"})
     : this.setState({ questionClicked : false, questionStatusImage : "../../images/faqHeaderArrowInactive.png"})
  }

  render(){
    var text = this.props.answer
    return(
      <div className="faqHeaderBoxContainer" onClick={this.answerQuestion}>
            <div className="faqHeaderBox">
                <h2 className="faqHeader">{this.props.header}</h2>
                <div className="faqHeaderArrowImageBox">
                    <img className="faqHeaderArrowImage" src={this.state.questionStatusImage} alt="FAQ expand answer"></img>
                </div>
            </div>
            {this.state.questionClicked === true ?
              <div class="faqAnswerBox">{text}</div> : null
            }
        </div>
    );
  }
}

export default FaqBoxContainer;