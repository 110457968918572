import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, withRouter, Link } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import './css/App.css';

import Home from './components/Home';
import About from './components/About.js';
import Products from './components/Products';
import Faq from './components/Faq';
import Contact from './components/Contact';
import Error from './components/Error';
import NavBar from './components/NavBar';
import Stock from './components/Stock';
import ProductMoreInformation from './components/ProductMoreInformation';
import Login from './components/Login';
import ProductListKeyed from './components/ProductsKeyed.json'
{/*import SmarterSpa from './components/SmarterSpa';
import SmarterSpaInLine from './components/SmarterSpaInLine';
import MegaChlorInLine from './components/MegaChlorInLine';
import ChlorMaker from './components/ChlorMaker';
import ChlorMakerInLine from './components/ChlorMakerInLine';*/}

export default function App(){
    return(

    <div>
      <Switch>
        <Route path='/' component={Home} exact/>
        <Route path='/about' component={About} />
        <Route path='/products' component={Products} />
        <Route path='/faq' component={Faq} />
        <Route path='/contact' component={Contact} />  
        <Route path='/productExample' render={(props) => (
          <ProductMoreInformation {...props} 
            productName={''}
            summary={''}
            features={''} 
            moreInformation={''}
            instructions={''}
            featureOne={''}
            featureTwo={''}
            featureThree={''}
            />
        )} /> 
        <Route path='/smarterSpa' render={(props) => (
          <ProductMoreInformation {...props} 
            productName={'SmarterSpa'}
            summary={'The SmarterSpa does not operate on a timed cycle like all other salt chlorine generators. Traditional salt systems turn on at a fixed cycle which is called the power level, they turn off if they need to or not. The SmarterSpa measures the chlorine, and when it needs more will start a chlorine generation cycle. Even if you set the chlorine production to maximum, it will not over-chlorinate your spa.'}
            features={'✔Built-in chlorine detection technology: will not over chlorinate your spa.\n ✔Easy to use control box: 3 buttons and 4 lights for operation, mounts to the spa’s skirt.\n✔Low 1,500 to 2,000 PPM salt concentration.\n✔Salt level indicator technology: lets you know when to add salt to maintain or boost chlorine production.\n✔Automatic cleaning: polarity reverses periodically to clean and help reduce calcium build-up.\n✔High current shutdown to protect the power supply and titanium plates if there is too much salt.\n✔Pure chlorination of up to 30 grams per day.\n✔10 time based power levels of chlorine production.\n✔Cell life span: 7,000 hours of chlorine production.\n✔Easy to replace cell.\n✔Cell simply drapes over the side of the spa into the water.\n✔Supports both 110 and 220 VAC supply voltage.\nAn inline model is also available.\n'} 
            moreInformation={'The SmarterSpa requires no spa modification. Simply attach the control panel to the spa skirt, add 1.5 pounds of salt per 100 gallons of spa water, drape the cell over the side into the spa water and plug it in. The power supply uses 110 / 220 VAC and outputs a low voltage 5Vdc to the Cell.\n\n'}
            instructions={'The SmarterSpa requires no spa modification. Simply attach the control panel to the spa skirt, add 1.5 pounds of salt per 100 gallons of spa water, drape the cell over the side into the spa water and plug it in. The power supply uses 110 / 220 VAC and outputs a low voltage 5Vdc to the Cell.\n\n'}
            featureOne={'For spas up to 1,000 gallons'}
            featureTwo={'Boost mode allows for manual control'}
            featureThree={'No spa modifications required'}
            productInfo={ProductListKeyed.smarterspa}
            />
        )} />
        <Route path='/SmarterSpaInLine' render={(props) => (
          <ProductMoreInformation {...props} 
            productName={'SmarterSpa (In-Line)'}
            summary={"The SmarterSpa doesn't operate on a timed cycle like all other salt chlorine generators. Traditional salt systems turn on at a fixed cycle which is called the power level, they turn off if they need to or not. The SmarterSpa measures the chlorine, and when it needs more will start a chlorine generation cycle. Even if you set the chlorine production to maximum, it will not over-chlorinate your spa."}
            features={'✔Built-in chlorine detection technology: will not over chlorinate your spa.\n✔Easy to use control box: 3 buttons and 4 lights for operation, mounts to the spa’s skirt.\,✔Low 1,500 to 2,000 PPM salt concentration.\n✔Salt Level Indicator Technology: Lets you know when to add salt to maintain or boost chlorine production.\n✔Automatic Cleaning: Polarity reverses periodically to clean and help reduce calcium build-up.\n✔High current shutdown to protect the power supply and titanium plates if there is too much salt.\n✔Pure chlorination of up to 30 grams per day.\n✔10 Time based power levels of chlorine production.\n✔Cell Life Span: 7,000 hours of chlorine production.\n✔Easy to replace cell.\n✔Cell simply drapes over the side of the spa into the water.\n✔Supports both 110 and 220 VAC supply voltage.\n\nAn inline model is also available.'} 
            moreInformation={"It can be a challenge to to keep the water in your spa or hot tub crystal clear and odor free. If you forget to add chlorine for a single day or the chlorine feeder runs out and you don't refill it that could lead to spa problems. Due to the higher temperature of the water the chemical balance is critical to be continuously maintained to keep it safe. Proper spa maintenance leads to your spa being safe when you want to use it. Even if the water looks good you should measure the chemical balance before use. Spa chlorine generators can greatly reduce spa problems and spa maintenance as they continuously make chlorine (or bromine) every day so you don't have to."}
            instructions={'The SmarterSpa requires no spa modification. Simply attach the control panel to the Spa skirt, add 1.5 pounds of salt per 100 gallons of spa water, drape the cell over the side into the spa water and plug it in. The power supply uses 110 / 220 VAC and outputs a low voltage 5Vdc to the Cell.'}
            featureOne={'For spas up to 1,000 gallons'}
            featureTwo={'Boost mode allows for manual control'}
            featureThree={'No spa modifications required'}
            productInfo={ProductListKeyed.smarterspaInLine}
            />
        )} />
        <Route path='/SmarterSpaReplacementElectrode' render={(props) => (
          <ProductMoreInformation {...props} 
            productName={'SmarterSpa Replacement Electrode'}
            summary={''}
            features={''} 
            moreInformation={''}
            instructions={''}
            featureOne={''}
            featureTwo={''}
            featureThree={''}
            productInfo={ProductListKeyed.smarterspaReplacement}
            />
        )} /> 
         <Route path='/SmarterSpaILReplacementElectrode' render={(props) => (
          <ProductMoreInformation {...props} 
            productName={'SmarterSpa-IL Replacement Electrode'}
            summary={''}
            features={''} 
            moreInformation={''}
            instructions={''}
            featureOne={''}
            featureTwo={''}
            featureThree={''}
            productInfo={ProductListKeyed.smarterspaILReplacement}
            />
        )} /> 
        <Route path='/MegaChlorInLine' render={(props) => (
          <ProductMoreInformation {...props} 
            productName={'MegaChlor (In-Line)'}
            summary={"The MegaChlor doesn't operate on a timed cycle like all other salt chlorine generators. Traditional salt systems turn on at a fixed cycle which is called the power level, they turn off if they need to or not. The MegaChlor measures the chlorine, and when it needs more will start a chlorine generation cycle. Even if you set the chlorine production to maximum, it will not over-chlorinate your pool."}
            features={'✔10 adjustable power levels: built-in ability to control chlorine production.\n✔Built-in chlorine detection technology: will not over chlorinate your spa.\n✔Easy to use control box: 3 buttons and 4 lights for operation.\n✔Cell fits inline with your existing pool pipework\n✔Automatic cleaning: polarity reverses periodically to clean and help reduce calcium build-up.\n✔3,000 to 4,000 PPM salt concentration.\n✔Salt level indicator technology: lets you know when to add salt to maintain or boost chlorine production.\n✔Boost mode for manual control.\n✔Pure chlorination up to 0.25 pounds per day.\n✔Easy to replace and clean the cell.\n✔Supports both 110 and 220 VAC supply voltage.\n✔High current shutdown to protect the power supply and titanium plates if there is too much salt.\n\nNB - Pool circulation pump needs to be on whilst chlorine is being produced.'} 
            moreInformation={'The Megachlor is installed inline with your existing pool pipework, after the pump, filter, and heater. If your pool pump runs on a timer you must also run the Megachlor on a timer ensuring it is only producing chlorine when the pool pump is running.'}
            instructions={'The MegaChlor requires no spa modification. Simply attach the control panel to the spa skirt, add 1.5 pounds of salt per 100 gallons of spa water, drape the cell over the side into the spa water and plug it in. The power supply uses 110 / 220 VAC and outputs a low voltage 5Vdc to the Cell.'}
            featureOne={'Suitable for up to 17,000 gallons'}
            featureTwo={'Three power Levels and boost mode'}
            featureThree={'Will not over chlorinate your pool'}
            productInfo={ProductListKeyed.megachlor}
            />
        )} /> 
        <Route path='/MegaChlorILReplacementElectrode' render={(props) => (
          <ProductMoreInformation {...props} 
            productName={'MegaChlor-IL Replacement Electrode'}
            summary={''}
            features={''} 
            moreInformation={''}
            instructions={''}
            featureOne={''}
            featureTwo={''}
            featureThree={''}
            productInfo={ProductListKeyed.megachlorILReplacement}
            />
        )} /> 
        <Route path='/ChlorMaker' render={(props) => (
          <ProductMoreInformation {...props} 
            productName={'ChlorMaker'}
            summary={'Simply attach the control panel to the spa skirt, add 1.5 pounds of salt per 100 gallons of spa water, drape over the cell into the spa water and plug it in.'}
            features={'✔Adjustable power levels: built-in ability to control chlorine production.\n✔10 time based power levels of chlorine production.\n✔Easy to use control box: 3 buttons and 4 lights for operation, mounts to the spa’s skirt.\n✔Salt level indicator technology: lets you know when to add salt to maintain or boost chlorine production.\n✔Pure chlorination of up to 30 grams per day.\n✔Vacation mode: lowers the output when inactive for two weeks.\n✔Cell simply drapes over the side of the spa into the water, In-line kit available.\n✔Cell life span: 7,000 hours of chlorine production.\n✔Easy to replace and clean the cell.\n✔Support both 110 and 220 VAC supply voltage.\n✔High current shutdown to protect the power supply and titanium plates if there is too much salt.'} 
            moreInformation={"It can be a challenge to to keep the water in your spa or hot tub crystal clear and odor free. If you forget to add chlorine or bromine for a single day or the chlorine feeder runs out and you don't refill it that could lead to spa problems. Due to the higher temperature of the water the chemical balance is critical to be continuously maintained to keep it safe. Proper spa maintenance leads to your spa being safe when you want to use it. Even if the water looks good you should measure the chemical balance before use. Spa chlorine generators can greatly reduce spa problems and spa maintenance as they continuously make chlorine (or bromine) every day so you don't have to."}
            instructions={'The ChlorMaker makes chlorine on a 3 hour cycle. Every 3 hours it starts making chlorine, and based on the power level it will turn off at some point in that 3 hours and remain in standby mode, then at the next 3 hours it will start again. At power level 5 it will be on for 45 minutes out of every 3 hours. If you use the spa and let the ChlorMaker know by pressing the boost mode button, it will be put into boost mode and be on for 3 times the normal on time. Boost mode helps to add the chlorine back after the spa is used.'}
            featureOne={'For spas up to 1,000 gallons'}
            featureTwo={'Boost mode: chlorine boost after spa use'}
            featureThree={'No spa modifications required'}
            productInfo={ProductListKeyed.chlormaker}
            />
        )} /> 
        <Route path='/ChlorMakerInLine' render={(props) => (
          <ProductMoreInformation {...props} 
            productName={'ChlorMaker (In-Line)'}
            summary={'Simply attach the control panel to the spa skirt, add 1.5 pounds of salt per 100 gallons of spa water, drape over the cell into the spa water and plug it'}
            features={'✔Adjustable power levels: built-in ability to control chlorine production.\n✔10 time based power levels of chlorine production.\n✔Easy to use control box: 3 buttons and 4 lights for operation, mounts to the spa’s skirt.\n✔Salt level indicator technology: lets you know when to add salt to maintain or boost chlorine production.\n✔Pure chlorination of up to 30 grams per day.\n✔Vacation mode: lowers the output when inactive for two weeks.\n✔Cell simply drapes over the side of the spa into the water, In-line kit available.\n✔Cell life span: 7,000 hours of chlorine production.\n✔Easy to replace and clean the cell.\n✔Support both 110 and 220 VAC supply voltage.\n✔High current shutdown to protect the power supply and titanium plates if there is too much salt.'} 
            moreInformation={"It can be a challenge to to keep the water in your spa or hot tub crystal clear and odor free. If you forget to add chlorine or bromine for a single day or the chlorine feeder runs out and you don't refill it that could lead to spa problems. Due to the higher temperature of the water the chemical balance is critical to be continuously maintained to keep it safe. Proper spa maintenance leads to your spa being safe when you want to use it. Even if the water looks good you should measure the chemical balance before use. Spa chlorine generators can greatly reduce spa problems and spa maintenance as they continuously make chlorine (or bromine) every day so you don't have to."}
            instructions={'The ChlorMaker makes chlorine on a 3 hour cycle. Every 3 hours it starts making chlorine, and based on the power level it will turn off at some point in that 3 hours and remain in standby mode, then at the next 3 hours it will start again. At power level 5 it will be on for 45 minutes out of every 3 hours. If you use the spa and let the ChlorMaker know by pressing the boost mode button, it will be put into boost mode and be on for 3 times the normal on time. Boost mode helps to add the chlorine back after the spa is used.'}
            featureOne={'For spas up to 1,000 gallons'}
            featureTwo={'Boost mode: chlorine boost after spa use'}
            featureThree={'No spa modifications required'}
            productInfo={ProductListKeyed.chlormakerIL}
            />
        )} /> 
        <Route path='/ChlorMakerReplacementElectrode' render={(props) => (
          <ProductMoreInformation {...props} 
            productName={'ChlorMaker Replacement Electrode'}
            summary={''}
            features={''} 
            moreInformation={''}
            instructions={''}
            featureOne={''}
            featureTwo={''}
            featureThree={''}
            productInfo={ProductListKeyed.chlormakerReplacement}
            />
        )} /> 
        <Route path='/ChlorMakerILReplacementElectrode' render={(props) => (
          <ProductMoreInformation {...props} 
            productName={'Chlormaker-IL Replacement Electrode'}
            summary={''}
            features={''} 
            moreInformation={''}
            instructions={''}
            featureOne={''}
            featureTwo={''}
            featureThree={''}
            productInfo={ProductListKeyed.chlormakerILReplacement}
            />
        )} />  
        <Route path='/stock' component={Stock} />
        <Route path='/login' component={Login} />
        <Route component={Error} />    
      </Switch>

      <NavBar style={{position:'absolute'}}></NavBar>
     </div>
  );
}