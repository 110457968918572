import React, {useEffect, useState } from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";

export default function Product(props){

  const [productName, setProductName] = useState('');
  const [cleanedProductName, setCleanedProductName] = useState('');


  const addZeroes = (num) => {
    const dec = num.toString().split('.')[1]
    const len = dec && dec.length > 2 ? dec.length : 2
    return Number(num).toFixed(len)
  }

  const removeWhiteSpace = (string) => {
    return string.replace(/\s/g, '')
  }

  const removePunctuation = (string) => {
    return string.replace(/[.,#!$%^&*;:{}=\-_`~()]/g,"")
  }

  useEffect(() => {
    if (props.name !== ''){
      setProductName(removeWhiteSpace(props.name));
      setCleanedProductName(removeWhiteSpace(removePunctuation(props.name)));
    }
  }, [props.name]);

  return(
    <div className="grid-item">
      <Link to ={`../../${cleanedProductName}`}>   
        <div className="productImageWrapper">
            {productName !== '' ?
            <img className="productImage" src={`images/${productName}.jpg`} alt={`${productName}`}></img>
            : null
            }
        </div>
        <h2 className="productTitle">{props.name}</h2>
        <p className="productPrice">£{addZeroes(props.price)}</p>    
        </Link>                     
    </div>
          
  );
}